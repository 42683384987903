
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import { useRouter } from 'vue-router'
import {
  getIndexConfig,
  getTableConfig,
  economicsStatusMap,
  economicsTypeMap,
  labelOptions,
  moneyConditionOptions,
} from './config'
import { getPageOfEnterprise } from './api'
import CButtonGroup from '@/components/c-button-group'
import permissionEnterprise from './permission-enterprise.vue'
import PaymentOperation from './payment-operation.vue'
import AffiliatedSubsidiaries from './affiliated-subsidiaries.vue'
import ConfigSetmeal from './config-setmeal.vue'
import BankAPPID from './bank-APPID.vue'
import ChatWiki from './chatwiki.vue'
// import IllegalTransferSettings from './illegal-transfer-settings.vue'
import IllegalTransferSettings from './components/associated-illegal-api/index.vue'
import { btnName } from '@/utils/index'
import { defineComponent, toRefs, reactive, onMounted, onActivated } from 'vue'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'enterprise-info-query',
  components: {
    CSearchList,
    CTable,
    permissionEnterprise,
    PaymentOperation,
    CButtonGroup,
    AffiliatedSubsidiaries,
    ConfigSetmeal,
    BankAPPID,
    IllegalTransferSettings,
    ChatWiki,
  },
  setup() {
    const router = useRouter()
    const data = reactive({
      ...getIndexConfig(),
      economicsStatusMap,
      economicsTypeMap,
      permissionVisible: false,
      isConfigSetmeal: false,
      isIllegalTransferSettings: false,
      searchParams: null,
      isChatwiki: false,
    })
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig(),
    })

    const onToggle = (name, row?) => {
      data[name] = !data[name]
      if (row) data.rowData = row
    }

    /**
     * 查询
     */
    const getPageOfEnterpriseList = () => {
      const obj: any = Object.assign(
        {},
        { ...data.queryForm },
        {
          currPage: tableConfig.pagination.currentPage,
          pageSize: tableConfig.pagination.pageSize,
          validPeriodStartTime:
            (data.queryForm as any).dateTimeArea instanceof Array
              ? dayjs((data.queryForm as any).dateTimeArea[0]).format(
                  'YYYY-MM-DD'
                )
              : '',
          validPeriodEndTime:
            (data.queryForm as any).dateTimeArea instanceof Array
              ? dayjs((data.queryForm as any).dateTimeArea[1]).format(
                  'YYYY-MM-DD'
                )
              : '',
        }
      )
      obj.carUsedFee = obj.carUsedFee && Number(obj.carUsedFee)
      getPageOfEnterprise(obj).then((res) => {
        tableConfig.data = res.data.list
        tableConfig.pagination.total = res.data.total
      })
    }

    /**
     * 查询2
     */
    const getPageOfEnterpriseList1 = (params) => {
      const obj: any = Object.assign(
        {},
        { ...params },
        {
          currPage: tableConfig.pagination.currentPage,
          pageSize: tableConfig.pagination.pageSize,
          validPeriodStartTime:
            (data.queryForm as any).dateTimeArea instanceof Array
              ? dayjs((data.queryForm as any).dateTimeArea[0]).format(
                  'YYYY-MM-DD'
                )
              : '',
          validPeriodEndTime:
            (data.queryForm as any).dateTimeArea instanceof Array
              ? dayjs((data.queryForm as any).dateTimeArea[1]).format(
                  'YYYY-MM-DD'
                )
              : '',
        }
      )
      obj.carUsedFee = obj.carUsedFee && Number(obj.carUsedFee)
      getPageOfEnterprise(obj).then((res) => {
        tableConfig.data = res.data.list
        tableConfig.pagination.total = res.data.total
      })
    }

    /**
     * @description: 执行搜索
     * @param {object} params
     * @return {void}
     */
    const handleSearch1 = (
      params: object | null,
      disabledPage?: boolean
    ): void => {
      !disabledPage && (tableConfig.pagination.currentPage = 1)
      params && (data.searchParams = { ...params })
      getPageOfEnterpriseList1(data.searchParams)
    }

    // keep-alive 缓存更新
    onActivated(() => {
      console.log('onActivated')
      handleSearch1(null, true)
    })
    /**
     * 重置
     */
    const handleReset = (val) => {
      data.queryForm = val
      getPageOfEnterpriseList()
    }
    const handleSearch = (params: object) => {
      data.queryForm = Object.assign({}, params)
      getPageOfEnterpriseList()
    }

    const pageCurrentChange = (val: number) => {
      tableConfig.pagination.currentPage = val
      getPageOfEnterpriseList()
    }

    const pageSizeChange = (val: number) => {
      tableConfig.pagination.currentPage = 1
      tableConfig.pagination.pageSize = val
      getPageOfEnterpriseList()
    }

    const enterpriseTarget = (name: string, params?: any) => {
      data.rowData = Object.assign({}, params)
      console.log('params--', data.rowData)
      if (name === 'enterprise-info-role') {
        data.permissionVisible = true
      } else if (name === 'isPaymentOperation') {
        data.isPaymentOperation = true
      } else if (name === 'enterprise-info-affiliated-subsidiaries') {
        data.isAffiliatedSubsidiaries = true
      } else {
        return router.push({ name: name, query: params })
      }
    }

    onMounted(() => {
      getPageOfEnterpriseList()
    })
    return {
      handleReset,
      handleSearch,
      pageCurrentChange,
      pageSizeChange,
      tableConfig,
      labelOptions,
      moneyConditionOptions,
      btnName,
      enterpriseTarget,
      getPageOfEnterpriseList,
      onToggle,
      ...toRefs(data),
    }
  },
})
