
import {
  defineComponent,
  reactive,
  toRefs,
  watchEffect,
  onMounted,
  ref,
} from 'vue'
import { ElMessage } from 'element-plus'
import { toResult } from '@/utils/toResult'
import { required } from '@/utils/rules'
import { chatwikiDetail, chatwikiSaveOrUpdate } from './api'

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false,
    },
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  // emits: ['update:modelValue'],
  setup(props, { emit }) {
    const data = reactive({
      visible: false,
      titlename: '编辑',
      formRef: undefined,
      formData: ref<any>({}),
      rules: {
        robotKey: [required],
        apiKey: [required],
        userName: [required],
        password: [required],
      },
    })

    watchEffect(() => {
      data.visible = props.modelValue
    })

    /**
     * @description: 关闭dialog
     * @param {*} void
     * @return {*}
     */
    const onCancel = (): void => {
      data.visible = false
      emit('update:modelValue', false)
      emit('onSuccess')
    }
    // 提交表单
    const handleSubmit = () => {
      data.formRef.validate(async (valid) => {
        if (valid) {
          const p = {
            ...data.formData,
            companyUuid: props.rowData.uuid,
          }
          const [err] = await toResult(chatwikiSaveOrUpdate(p))
          if (err) return false
          ElMessage.success('操作成功')
          onCancel()
        }
      })
    }

    // 初始化
    const init = async () => {
      const p = {
        uuid: props.rowData.uuid,
      }
      const [err, res] = await toResult(chatwikiDetail(p))
      if (err) return false
      console.log(res)
      data.formData = res
    }

    onMounted(() => {
      init()
    })
    return {
      ...toRefs(data),
      handleSubmit,
      onCancel,
    }
  },
})
